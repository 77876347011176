<template>
  <div>
    <div class="main">
      <!-- <image-slider /> -->
    </div>
    <div class="bg-text">
      <h1><img src="../assets/logo-white.png"> HEIMKOK</h1>
      <p>98611167 | sstorset@gmail.com</p>
    </div>
</div>
</template>

<script>
// import ImageSlider from './ImageSlider.vue';

export default {
  components: {
    // ImageSlider,
  }
}
</script>

<style scoped>
h1 {
  font-size: 5.5rem
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 3rem;
  }
 }

@media all and (min-width: 480px) and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
 }

@media all and (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }
 }

.main {
  background-image: url("../assets/heimkok.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(2px);
  width: 100vw;
  height: 100vh;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.2); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

</style>
